<template>
  <v-form
    ref="form"
    class="rcc-return-statuses-form"
    v-model="valid"
    @submit.prevent="onSubmitForm"
  >
    <div class="rcc-return-statuses-form__form-body">
      <rcc-text-input
        label="Статус"
        width="40%"
        v-model="form.name"
        :rules="[value => isRequiredField({ value, field: 'Название' })]"
      />

      <rcc-checkbox
        v-model="form.is_validate"
        :label="validationCheckboxLabel"
      />
    </div>

    <rcc-footer-buttons :is-submit="isSubmit" @cancel-click="$router.push({ name: 'return-statuses' })"/>
  </v-form>
</template>

<script>
import ReturnStatusApi from '@/api/return-statuses'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccTextInput from 'Components/controls/rcc-text-input'
import RccCheckbox from 'Components/controls/rcc-checkbox'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccCheckbox
  },

  mixins: [Page, Form, ReturnStatusApi],

  data() {
    return {
      form: {
        name: '',
        is_validate: false
      }
    }
  },

  computed: {
    validationCheckboxLabel() {
      return 'Использовать для валидации "Факт. дата" и "Факт. количество"в таблице возвратов'
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-return-statuses-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body {
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__booking-section {
    display: flex;
    gap: 20px;
  }

  &__number-input {
    flex: none;
    width: 250px;
  }

  &__form-section {
    margin: 10px 0;
  }
}
</style>
